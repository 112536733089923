import axios from "@/helpers/axios";
import { IPaginatedResponse } from "../../../interfaces/IPaginatedResponse";
import { UserMobile } from "../../domain/userMobile";
import { IUserMobileRepository } from "@/determiners/ports/IUserMobileRepository";

export class UserMobileApiAdapter implements IUserMobileRepository {
  async unasignedDevice(id: string): Promise<boolean> {
    const response = await axios.delete(
      `/api/users/delete_mobile_device/${id}/`
    );
    return response.status === 200;
  }
  async getUserMobile(
    url: string,
    adicionalHeader: Record<string, string> = {}
  ): Promise<IPaginatedResponse<UserMobile>> {
    return axios.get(url, {
      headers: {
        ...adicionalHeader,
      },
    });
  }
}
