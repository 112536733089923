import { UserMobile } from "./userMobile";
import { IUserMobileRepository } from "../ports/IUserMobileRepository";
import { IPaginatedResponse } from "../../interfaces/IPaginatedResponse";

export class ListUserMobileUseCase {
  constructor(private repository: IUserMobileRepository) {}

  async execute(
    restApiUrl: any,
    axiosHeader: any
  ): Promise<IPaginatedResponse<UserMobile>> {
    return this.repository.getUserMobile(restApiUrl, axiosHeader);
  }
}
