import { defineStore } from "pinia";

import type { Modal } from "@/interfaces/modals/modals.interface";

export const useCommonModal = defineStore("modalStore", {
  state: () => ({
    modals: {
      showSummaryHandlerModal: false,
      showEditOrCreateUserModal: false,
      showAdsingDeterminerUserModal: false,
      showEditOrCreateNoteModal: false,
      showConfirmUnasignedDeterminer: false,
      showConfirmDowloadDeterminer: false,
      showEditOrCreateAgentsModal: false,
      showImportDevices: false,
      showConfirmDeleteUserMobile: false,
      showAvanceFilterClient: false,
    } as Modal,
  }),
  actions: {
    changeMultiModalState(key: string, value: boolean) {
      this.modals[key] = value;
    },
  },
});
